/* Sidebar Styles */
.sidebar-container {
  @apply fixed inline-flex flex-row justify-center bottom-2 z-10;
  left: 50%;
  transform: translateX(-50%);
  height: 48px;
}

.sidebar {
  @apply fixed inline-flex flex-row justify-center items-center border border-[rgba(179,175,215,0.12)] rounded-lg drop-shadow-[0_3px_12px_rgba(162,166,217,0.09)] p-2 z-10;
  gap: 0.5rem;
  background: #fdfcfcea;
  backdrop-filter: blur(8px);
}

.sidebar-button {
  border-radius: 0.25rem; /* rounded */
  background-color: transparent; /* Ensure no background color by default */
  transition: background-color 0.2s ease-in-out; /* transition ease-in-out duration-200 */
}

.sidebar-button:hover {
  background-color: #f2f3f7; /* hover:bg-gray-300 */
}

.sidebar-button:active {
  background-color: #e6e9f0; /* active:bg-gray-400 */
}

.sidebar-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sidebar-container > :not([hidden]) ~ :not([hidden]) {
  margin-top: 0;
  margin-bottom: 0;
}

/* Reflect Button Styles */
.reflect-button {
  /* Base styles */
  height: 32px;
  padding: 0.1rem 1rem;
  display: flex;
  gap: 0.2rem;
  margin-left: 8px;

  align-items: center;
  position: relative;
  z-index: 1;

  /* Text styles */
  color: #322e33;
  font-family: "gelica", sans-serif;

  /* Shape */
  border-radius: 6px;
  border: 1px solid #e6d0d5;

  /* Base gradient */
  background: radial-gradient(48px 16px at center, #f4c8c880 0%, transparent 100%),
    linear-gradient(270deg, #f2e6e6 0%, #f1ebea 83%);

  /* Shadows */
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.2),
    /* Outer shadow */ inset 0px -4px 6px rgba(229, 208, 210, 0.5),
    /* Inner highlight */ inset 0px 0px 2px 2px rgb(219, 190, 192, 0.2),
    /* Inner highlight */ inset 0px 2px 3px 3px rgba(255, 255, 255, 0.5); /* Inner highlight */
}

.reflect-button:hover + .tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0.5s;
}

.reflect-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background: linear-gradient(10deg, #f2e6e600 60%, hsla(260, 46%, 59%, 0.05) 100%),
    radial-gradient(48px 16px at center, #f4c8c880 0%, transparent 100%),
    linear-gradient(hsla(336, 42%, 46%, 0.02) 0%, hsla(336, 42%, 46%, 0.02) 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
  z-index: -1;
}

.reflect-button:hover::before {
  opacity: 1;
}

.reflect-button:active {
  background: radial-gradient(48px 16px at center, #f4c8c880 0%, transparent 100%),
    linear-gradient(hsla(336, 42%, 46%, 0.08) 0%, hsla(336, 42%, 46%, 0.08) 100%),
    linear-gradient(270deg, #f2e6e6 0%, #f1ebea 83%);

  border: 1px solid #dcbdc3;

  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.2),
    /* Outer shadow */ inset 0px 8px 6px 1px hsla(354, 27%, 78%, 0.2),
    /* Inner highlight */ inset 0px 0px 4px 4px rgba(219, 190, 192, 0.25),
    /* Inner highlight */ inset 0px 4px 3px 3px rgba(255, 255, 255, 0.25); /* Inner highlight */
}

.sparkle-sidebar-icon {
  width: 0.8rem; /* Adjust the size as needed */
  height: 0.8rem; /* Adjust the size as needed */
}

.sidebar-divider {
  width: 1px;
  height: 32px;
  border-radius: 0.5px;
  background: rgba(179, 175, 215, 0.12);
}

.reflect-button > * {
  position: relative;
  z-index: 2;
}
