.tiptap {
  color: #322e33; /* Darker color when there's content */
  font-size: 1rem;
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  line-height: 150%;
  /* p {
    font-size: 0.875rem;
  } */
  strong {
    font-family: "instrument-sans-variable", sans-serif;
    font-variation-settings: "wght" 600;
  }

  ul,
  ol {
    padding: 0 1rem;
    margin: 1rem 0;
    margin-left: 1rem;
  }

  ul {
    list-style-type: disc; /* Adds bullet points */
  }

  ol {
    list-style-type: decimal; /* Adds numbers */
  }

  /* For nested lists */
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    margin: 0.5rem 0;
  }

  ul ul ul {
    list-style-type: square; /* Changes bullet style for deeply nested lists */
  }

  h1 {
    font-size: 2rem; /* 30px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 1.8rem; /* 24px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  h3 {
    font-size: 1.6rem; /* 20px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1.4rem; /* 18px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  h5 {
    font-size: 1.2rem; /* 16px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  h6 {
    font-size: 1.1rem; /* 16px */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  blockquote {
    margin: 1.5rem 0;
    padding: 1rem 1.5rem;
    border-left: 2.5px solid #bc789286;
    background: #c07a951f;
    border-radius: 1.75px;

    /* Handle nested paragraphs inside blockquotes */
    p {
      margin: 0;
      line-height: 1.6;
    }

    /* Style nested blockquotes differently */
    blockquote {
      margin: 1rem 0;
      border-left-color: rgba(165, 105, 128, 0.5);
      background: rgba(165, 105, 128, 0.02);
    }
  }

  hr {
    border: none;
    margin: 2rem 0;
    height: 1px;
    background: #c07a951f;
    border-radius: 999px;
  }

  hr:hover {
    cursor: pointer;
    background: #c07a951f;
  }
}

.note-editor-content {
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  flex-grow: 1;
}

.note-editor-content:placeholder-shown {
  color: #3838688c; /* Color when empty */
}

.note-editor-content:not(:placeholder-shown) {
  color: #322e33; /* Darker color when there's content */
}

.bubble-menu {
  @apply justify-center items-center border border-[#E9E9F1] rounded-lg drop-shadow-[0_5px_20px_rgba(185,186,213,0.12)] bottom-2 left-1/2 p-2 space-x-1 z-10;
  background: linear-gradient(to bottom, #fcfcfc 30%, #fbfbfb);
  background-color: "white";
  /* border: 1px solid lightgray; */
  display: flex;
  color: #322e33;
  padding: 0.25rem;
  gap: 0.075rem;

  button {
    font-size: 0.875rem;
    background-color: unset;
    border-radius: 0.25rem;
    padding: 0.3rem 0.75rem 0.1rem 0.75rem;

    &:hover {
      background-color: #f2f3f7; /* hover:bg-gray-300 */
    }

    &.is-active {
      background-color: #e6e9f0; /* active:bg-gray-400 */

      &:hover {
        background-color: #e6e9f0;
      }
    }
  }
}

.reflection-node {
  font-style: italic;
  color: #a56980;

  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  background: linear-gradient(135deg, #b9839c 0%, #a28ba7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #b9839c; /* Fallback color */
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  align-items: center;

  margin-top: 1rem;
  margin-bottom: 1rem;
  contenteditable: false;
}

.reflection-container {
  font-style: italic;
  color: #a56980;

  /* display: flex;
    /* gap: 0.5rem; */
  /* align-items: flex-start; */
  /* line-height: 4; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.reflection-content {
  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  background: linear-gradient(135deg, #b9839c 0%, #a28ba7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #b9839c; /* Fallback color */
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  align-items: center;
}

/* Style for the most recent reflection */
.reflection-content:last-child {
  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  background: linear-gradient(135deg, #a56980 0%, #9e6aa1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #a56980; /* Fallback color */
  display: flex;
  gap: 0.375rem;
}

.reflection-sparkle {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

/* TiptapEditor Styles */
.tiptap-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(253, 250, 251, 1);
  color: #322e33;
  position: relative;
  /* @media (min-width: 0px) {
    padding-bottom: 1rem;
  }
  @media (min-width: 1024px) {
    padding-bottom: 6rem;
  } */
  width: 100%;
  margin: 0 auto;

  /* max-width: 800px; */
  overflow-x: hidden;
  padding-top: 1rem;
  hyphens: none;
  word-break: normal;
  overflow-wrap: break-word;
}

.tiptap-editor-header {
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  position: sticky;
  top: 0;
  z-index: 20;
  background: #fdfafb;
  padding-inline: max(24px, calc((100% - 700px) / 2));
  box-shadow: 0 4px 12px 12px #fdfafb;
}

.tiptap-editor-date {
  font-size: 0.875rem;
  color: #3838688c;
  margin-top: 0.45rem;
}

@media (max-width: 768px) {
  .tiptap-editor-date {
    margin-left: 0.75rem;
  }
}

.saving-indicator {
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: #3838688c;
  position: relative;
  margin-top: 0.45rem;
  margin-left: 0.75rem;
}

.saving-state {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
}

.saving-state.active {
  opacity: 1;
  visibility: visible;
}

.tiptap-editor-emotion-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 18.12px;
  gap: 12px;
  margin-top: 70px;
  margin-bottom: 0.75rem;
  margin-left: 0.35rem;
}

@media (max-width: 768px) {
  .tiptap-editor-emotion-container {
    margin-top: 2rem;
  }
}

.tiptap-editor-emotion {
  display: flex;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 11px;
  font-variation-settings: "wght" 500;
  margin-left: -0.35rem;
  padding: 0.32rem 0.5rem 0.125rem 0.5rem;
  border-radius: 9999px;
  justify-content: center;
}

@media (max-width: 768px) {
  .tiptap-editor-emotion {
    width: fit-content;
  }
}

.tiptap-editor-title {
  width: 100%;
  background-color: transparent;
  font-size: 1.125rem;
  font-family: "gelica", sans-serif;
  font-variation-settings: "wght" 400;
  margin-bottom: 1.5rem;
  outline: none;
  color: #322e33;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  resize: none;
  min-height: 1.5em;
  height: auto;
}

.tiptap-editor-title::placeholder {
  color: #3838688c;
}

.tiptap-editor-title:not(:placeholder-shown) {
  color: #322e33;
}

.tiptap-editor-title.has-content {
  color: #322e33;
}

.editor-wrapper {
  z-index: 2;
  position: relative;
  overflow: auto;
  padding-right: 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  max-width: 100%;
  padding-right: 2rem;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(56, 56, 104, 0.12) transparent;
  padding-inline: max(24px, calc((100% - 700px) / 2)); /* to keep scrollbar position right*/
  padding-bottom: 24vh; /* to add some padding to bottom; i think notioni does this?*/
}

/* Tiptap specific styles */
.tiptap {
  ul,
  ol {
    padding: 0 1rem;
    margin: 1rem 0;
    margin-left: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #322e33;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    border: none;
    margin: 2rem 0;
    height: 2px;
    background: #c07a951f;
    border-radius: 999px;
  }

  hr:hover {
    cursor: pointer;
    background: #b96b8934;
  }
}

.tiptap-editor-emotion-placeholder {
  color: #3838688c;
  background-color: #f7f7fa;
  font-style: italic;
}

.reflection-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #3838688c;
  opacity: 0;
  transition: opacity 0.2s;
}

.reflection-container:hover .reflection-btn {
  opacity: 1;
}

.reflection-btn:hover {
  color: #a56980;
}

.reflection-prompt-container {
  font-style: italic;
  color: #a56980;

  /* display: flex;
    /* gap: 0.5rem; */
  /* align-items: flex-start; */
  /* line-height: 4; */
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;

  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  /* background: linear-gradient(135deg, #b9839c 0%, #a28ba7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background-clip: text;
  color: #b9839c; /* Fallback color */
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  align-items: center;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #3838688c;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

kbd {
  font-family: "gelica", sans-serif;
  font-weight: 400;
  font-variation-settings: "wght" 400;
  background: linear-gradient(135deg, #b9839c 0%, #a28ba7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: #b9839c; /* Fallback color */
  font-style: italic;
  display: inline-block;
  padding: 0 2px;
}

/* .ProseMirror {
  flex: 1;
  min-height: calc(100vh - 240px);
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: text;
  caret-color: currentColor;
  caret-shape: bar;
  vertical-align: middle;
} */

/* If needed, we can also try setting a specific caret position */
.ProseMirror::selection {
  vertical-align: middle;
}

/* Hide the editor placeholder when title is focused */
.tiptap-editor-title:focus ~ .ProseMirror p.is-editor-empty:first-child::before {
  content: none;
}

/* Or if you're using a data-placeholder attribute */
.tiptap-editor-title:focus ~ .ProseMirror p[data-placeholder].is-editor-empty:first-child::before {
  content: none;
}

/* Hide title placeholder when focused */
.tiptap-editor-title:focus::placeholder {
  opacity: 0;
}

.animate-in {
  animation: scaleIn 0.2s ease-out;
  transform-origin: center;
}

@keyframes scaleIn {
  from {
    opacity: 0.5;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Scrollbar styling for editor wrapper */

.editor-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.editor-wrapper::-webkit-scrollbar-track {
  background: transparent;
  width: 4px !important;
}

.editor-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(56, 56, 104, 0.12);
  border-radius: 2px;
  width: 4px !important;
  min-width: 4px !important;
  max-width: 4px !important;
}

.editor-wrapper::-webkit-scrollbar-thumb:hover {
  width: 4px !important;
  background-color: rgba(56, 56, 104, 0.12);
}

.reflection-actions {
  display: flex;
  gap: 4px;
  align-items: center;
}
/* 
.reflection-feedback-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.reflection-feedback-btn:hover {
  background-color: #f0f0f0;
  color: #333;
} */

.feedback-positive {
  color: #22c55e !important; /* green */
}

.feedback-negative {
  color: #ef4444 !important; /* red */
}

.ProseMirror {
  hyphens: none;
  word-break: normal;
  overflow-wrap: break-word;
}

.continue-button {
  @apply text-left rounded-2xl text-xs flex items-center gap-1;
  background: #efe7eb;
  color: #3838688a;
  padding: 6px 12px 4px 12px;
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  margin-top: 24px;
  width: fit-content;
  font-family: "instrument-sans-variable", sans-serif;

  .reflection-sparkle {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-bottom: 3px;
  }
}

.continue-button:hover:not(:disabled) {
  background: #EDE1E8;
  color: #3838688c;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.12);
  
}

.continue-button:disabled {
  background: #EDE1E8;
  color: #38386869;
  box-shadow: none;
}

.review2024-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.complete-button {
  background: #3838687e;
  color: #FFFFFF;
}

.complete-button:hover:not(:disabled) {
  background: #3838688c;
  color: #FFFFFF;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.4);
}

.complete-button:disabled {
  background: #38386869;
  color: #FFFFFF;
  box-shadow: none;
}

@keyframes loadingDots {
  0% { content: ""; }
  25% { content: "."; }
  50% { content: ".."; }
  75% { content: "..."; }
  100% { content: ""; }
}

.continue-button:disabled::after,
.complete-button:disabled::after {
  content: "";
  display: inline-block;
  text-align: left;
  animation: loadingDots 1s infinite;
}