/* Desktop Styles */
@media (min-width: 769px) {
  .note-list-container {
    @apply overflow-y-auto flex flex-col border border-[rgba(179,175,215,0.12)] rounded-lg drop-shadow-[0_5px_12px_rgba(162,166,217,0.06)] px-3;
    padding-top: 1.45rem;
    height: calc(100% - 1rem);
    width: 18rem; /* or your desired width */
    margin-left: 0rem;
    background: #fdfafb;
    z-index: 2;
    position: relative;
    isolation: isolate;
    overflow: hidden;
  }

  .note-list-container.collapsed {
    width: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease, width 0s ease-in 0.15s;
  }

  .note-title {
    @apply text-sm w-full;
    font-variation-settings: "wght" 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    color: #322e33;
    line-height: 1.5;
  }

  .note-metadata-container {
    @apply flex flex-row items-center;
    gap: 10px;
  }

  .note-list {
    @apply overflow-y-auto flex flex-col flex-1 gap-2;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
  }

  .note-editor-header {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 5rem; /* mb-4 */
    margin-top: 0.35rem; /* mb-4 */
  }
  
  .note-list-header {
    position: sticky;
    top: 0;
    z-index: 40;
    @apply flex h-6 justify-between items-center pl-3;
    background: #fdfafb;
    box-shadow: 0 0px 12px 12px #fdfafb;
    width: 100%;
  }

}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Target the root container */
  .route-container {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
    scrollbar-width: thin !important;
    scrollbar-color: rgba(56, 56, 104, 0.12) transparent;
  }

  /* Webkit scrollbar styles */
  .route-container::-webkit-scrollbar {
    width: 4px !important;
  }

  .route-container::-webkit-scrollbar-track {
    background: transparent;
    width: 4px !important;
  }

  .route-container::-webkit-scrollbar-thumb {
    background-color: rgba(56, 56, 104, 0.12);
    border-radius: 2px;
    width: 4px !important;
    min-width: 4px !important;
    max-width: 4px !important;
  }

  .route-container::-webkit-scrollbar-thumb:hover {
    width: 4px !important;
    background-color: rgba(56, 56, 104, 0.12);
  }

  .note-list-item {
    width: 100%;
    overflow-x: hidden;
    padding: 0.75rem;
    margin: 0;
  }

  .note-preview {
    width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .note-list-container {
    @apply overflow-y-auto flex flex-col h-full rounded-lg pt-4 px-2 space-y-4;
    width: 100vw;
    max-width: 100vw;
    margin-left: 0;
    box-sizing: border-box;
    flex: 1;
    position: relative;
    overflow-x: hidden;
  }

  .note-title {
    font-size: 16px !important;
    font-variation-settings: "wght" 500;
    color: #322e33;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: pre-line;
  }

  .note-list {
    @apply overflow-y-auto flex flex-col flex-1 gap-2;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    position: relative;
    overflow-x: hidden;
    margin-top: 0 !important;
  }

  .note-list::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  .note-editor-header {
    margin-bottom: 1rem; /* mb-4 */
    margin-top: 0.35rem; /* mb-4 */
  }

  .note-list-header {
    position: sticky;
    top: 0;
    z-index: 40;
    @apply flex h-8	justify-between items-center pl-3 pb-4 mr-8;
    background: #fdfafb;
    box-shadow: 16px -8px 12px 20px #fdfafb;
  }

  .note-list-item {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .note-preview {
    font-size: 16px !important;
    line-height: 1.4;
    margin-top: 4px;
  }

  .note-date {
    font-size: 12px;
    padding-top: 4px;
  }

  .note-list-item {
    padding: 1rem;
  }

  .note-metadata-container {
    @apply flex flex-row items-center;
    gap: 16px;
    margin-top: 4px;
  }
}

.search-input-container {
  @apply flex items-center w-full gap-2;
}

.search-input {
  @apply flex-1 h-8 border border-[rgba(179,175,215,0.12)] rounded pl-2 text-sm;
  background-color: rgba(252, 252, 252);
  padding-top: 3px;
}

.search-input::placeholder {
  @apply text-[#3838688C];
}

.cancel-search-button {
  @apply ml-2;
}

.note-list-title {
  @apply text-lg font-normal text-[#322E33];
  font-family: "gelica", sans-serif;
  color: #322e33;
}

.search-button {
  @apply h-6 w-6 rounded bg-transparent transition-colors duration-200 ease-in-out flex items-center justify-center text-[#322E33];
}

.search-button:hover {
  @apply bg-[#F2F3F7];
}

.search-button:active {
  @apply bg-[#E6E9F0];
}

.note-list-item {
  @apply rounded-md cursor-pointer;
  padding-right: 16px;
  position: relative;
  background: transparent;
  padding: 0.75rem; /* 12px padding all around */
  background: #fdfafb;
  z-index: 30;
}

.note-list-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.375rem; /* match the parent's border-radius */
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

.note-list-item:hover::before {
  opacity: 1;
}

.note-list-item.selected::before {
  background: linear-gradient(135deg, #f1eff4 0%, #f5eff6 100%);
  opacity: 1;
}

.note-date {
  @apply text-xs text-[#3838688C] pt-2;
  font-size: 10px;
  font-variation-settings: "wght" 500;
}

.note-emotions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  margin-left: 0.35rem;
}

.note-emotion {
  font-size: 0.5rem;
  padding: 0.125rem 0.25rem;
  border-radius: 9999px;
  text-transform: uppercase;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  /* Add any other necessary styling */
}

.note-list-spacer {
  height: 8px;
  width: 100%;
  background: transparent;
  position: relative;
  z-index: 1;
  flex-shrink: 0;
}

.note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visibility-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  color: #3838688c;
  opacity: 0;
  transition: opacity 0.2s;
}

.note-list-item:hover .visibility-toggle {
  opacity: 1;
}

.blurred {
  filter: blur(4px);
  transition: filter 0.2s;
}

.note-list-item:hover .blurred {
  filter: blur(0);
}

.note-preview {
  @apply font-normal text-sm;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #322e33;
  -webkit-line-clamp: 1;
  white-space: pre-line;
}

.delete-indicator {
  background: #ff4444;
  position: absolute;
  right: 1px;
  top: 1px;
  height: 98%;
  width: 98%;
  z-index: 0;
  border-radius: 0.375rem; /* match the parent's border-radius */
  padding: 0.75rem; /* 12px padding all around */
}

.icon-button-group {
  display: flex;
  gap: 0.5rem;
  z-index: 30;
  overflow: visible;
  isolation: isolate;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Position the expand button container */
.expand-button-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.expand-button-container:not(.collapsed) {
  opacity: 1;
}

.expand-button {
  background: transparent;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.note-list-bottom-spacer {
  height: 4px;
  width: 100%;
  flex-shrink: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .note-list-bottom-spacer {
    height: 60px;
    width: 100%;
    flex-shrink: 0;
  }
}

/* Add sticky header styles */
.search-bar {
  position: sticky;
  z-index: 10;
  top: 48px;
  padding-top: 24px;
  margin-top: -32px;
  width: 100%;
  display: flex;
  background: #fdfafb;  /* Using your app's background color instead of CSS variable */
}

/* For mobile */
@media (max-width: 768px) {
  .search-bar {
    top: 0;
    padding-top: 16px;
    margin-top: -16px;
  }
}
