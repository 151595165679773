/* NoteEditor Styles */
.note-editor-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent;
  color: #322e33;

  /* mobile
  @media (max-width: 1024px) {
  }

  @media (min-width: 1025px) {
    padding-bottom: 6rem;
  }
     desktop */

}

.note-editor-date {
  font-size: 0.875rem; /* text-sm */
  color: #3838688c; /* text-gray-500 */
}

.saving-indicator {
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  color: #3838688c;
  position: relative;
}

.saving-state {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s ease-in-out, visibility 0.1s ease-in-out;
}

.saving-state.active {
  opacity: 1;
  visibility: visible;
}

.saving-indicator span span {
  display: inline-flex;
}

.note-editor-emotions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 1rem;
}

.note-editor-emotions-title {
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-bold */
  color: #322e33;
}

.note-editor-emotions-list {
  display: flex;
  flex-wrap: wrap;
}

.note-editor-emotion {
  display: flex;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 11px;
  font-variation-settings: "wght" 500;
  margin-left: -0.35rem;
  padding: 0.32rem 0.5rem 0.125rem 0.5rem; /* top right bottom left */
  border-radius: 9999px;
  justify-content: center;
}

.note-editor-title {
  /* this should resize to the text */
  width: 100%;
  background-color: transparent;
  font-size: 1.125rem; /* text-2xl */
  font-family: "gelica", sans-serif;
  font-variation-settings: "wght" 400;
  margin-bottom: 0.75rem; /* mb-4 */
  outline: none; /* focus:outline-none */
  color: #322e33;
}

.note-editor-title:placeholder-shown {
  color: #3838688c; /* Keep placeholder color lighter */
}

.note-editor-title:not(:placeholder-shown) {
  color: #322e33;
}

.note-editor-title.has-content,
.note-editor-content.has-content {
  color: #322e33; /* Darker color when there's content */
}

/* NoteEditor.css */

.note-editor-emotion-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 1.05rem;
  margin-left: 0.35rem;
}
