/* mobile styles */
@media (max-width: 768px) {
  .dashboard-container {
    @apply flex flex-col px-6 pt-7;
    width: 100%;
    margin-left: 0;
    background: #fcf9fa;
    z-index: 2;
    padding-bottom: 69px;
    border: none;
    box-shadow: none;
  }

  .zero-state-container {
    padding: 110px 24px 36px 24px;
    background: none;
    border-radius: 0;
  }

  .zero-state-message {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0 12px;
  }

  .zero-state-submessage {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0 12px;
    color: #3838688c;
  }

  .zero-state-icon {
    width: 24px;
    height: 24px;
  }

  .dashboard-title {
    font-size: 24px;
  }

  .heading-1 {
    font-size: 12px !important;
  }

  .emotion-summary-number {
    font-size: 40px;
    line-height: 40px;
  }

  .emotion-summary-emotion {
    font-size: 12px !important;
  }

  .week-description {
    font-size: 14px !important;
    line-height: 160% !important;
  }

  .descriptive-word {
    font-size: 12px !important;
  }

  .descriptive-star {
    font-size: 7px !important;
  }

  .moments-description {
    font-size: 16px !important;
  }

  .moments-description-time {
    font-size: 14px !important;
  }

  .theme-items {
    gap: 8px !important;
  }

  .theme-item {
    font-size: 14px !important;
  }

  /* Hide the overlay on mobile */
  .emotion-summary-overlay {
    display: none;
  }

  .weekly-review-button {
    min-height: 50px !important;
    margin-top: 12px;
    margin-bottom: 4px;
    padding: 0.5rem 1rem !important;
  }

  .button-text {
    font-size: 16px !important;
  }

  .weekly-review-icon {
    width: 18px;
    height: 18px;
  }
}

/* desktop styles */
@media (min-width: 768px) {
  .dashboard-container {
    @apply overflow-y-auto flex flex-col border border-[rgba(179,175,215,0.12)] rounded-lg drop-shadow-[0_5px_12px_rgba(162,166,217,0.06)] px-3;
    padding-top: 1.45rem;
    height: calc(100% - 1rem);
    width: 18rem;
    margin-left: 0rem;
    background: #fdfafb;
    z-index: 2;
    transition: opacity 0.2s ease, width 0.2s ease-in-out;
    opacity: 1;
    position: relative;
    isolation: isolate;
  }

  .dashboard-container.collapsed {
    width: 0;
    opacity: 0;
    pointer-events: none;
    padding: 0;
    overflow: hidden;
    border: none;
    transition: opacity 0.1s ease, width 0.25s ease-in-out;
  }
}

.dashboard-spacer {
  height: 1px;
  width: 100%;
  background: transparent;
  position: relative;
  z-index: 1;
}

.dashboard-header {
  position: sticky;
  top: 0;
  z-index: 10;
  @apply flex h-6 justify-between items-center pl-3;
  background: #fdfafb;
  box-shadow: 0 4px 12px 12px #fdfafb;
}

.dashboard-title {
  @apply text-lg font-normal text-[#322E33];
  font-family: "gelica", sans-serif;
  color: #322e33;
}

.heading-1 {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 500;
  font-size: 10px;
  text-transform: uppercase;
  color: #3838688c;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
}

.heading-2 {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  color: #3838688c;
  font-style: italic;
}

.theme-sections {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.themes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
  border-radius: 0.375rem;
  padding: 12px;
}

.theme-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.theme-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.theme-item {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  color: #322e33;
}

.emotion-summary-section {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  padding: 12px;
  padding-top: 18px;
  overflow: hidden;
}

.emotion-summary-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
}

.emotion-summary-container {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: start;
  background-color: transparent;
  gap: 2px;
}

.emotion-summary-number {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 300;
  font-size: 32px;
  line-height: 32px;
}

.emotion-summary-emotion {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 10px;
  text-transform: lowercase;
  margin: 0;
}

.week-description {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  line-height: 150%;
}

.descriptive-words {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 32px;
}

.descriptive-word {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 10px;
}

.descriptive-star {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 5px;
}

.stats-grid {
  @apply overflow-y-auto flex flex-col flex-1;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.other-stats-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
}

.moments-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 14px 12px 2px 12px;
  background: radial-gradient(circle at 35% 80%, #f4c7c75e 0%, #f5d4cb00 50%),
    linear-gradient(100deg, #f5f3f6 0%, #f7f2f6 100%);
  border-radius: 0.375rem;
}

.moments-number {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 50px;
  line-height: 110%;
  color: #322e33;
}

.moments-description-container {
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
}

.moments-description {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 14px;
  color: #322e33;
}

.moments-description-time {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  color: #3838688c;
}

.notes-to-self-section {
  display: flex;
  flex-direction: column;
  padding: 11px;
  width: 50%;
  justify-content: space-between;
  background: linear-gradient(100deg, #f5f3f6 0%, #f7f2f6 100%);
  border-radius: 0.375rem;
}

.notes-to-self-title {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  line-height: 16px;
  color: #322e33;
}

.notes-to-self-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.notes-to-self-item {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 10px;
  color: #3838688c;
}

.weekly-review-button {
  min-height: 36px;
  padding: 0.2rem 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  cursor: pointer;
  mix-blend-mode: multiply;
  margin-top: 8px;
  width: 100%;
}

.weekly-review-button::before,
.weekly-review-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  z-index: -1;
}

.weekly-review-button::before {
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
}

.weekly-review-button::after {
  background: linear-gradient(135deg, #edebf0 0%, #eee6f0 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.weekly-review-button:hover::after {
  opacity: 1;
}

.weekly-review-button:active::after {
  background: linear-gradient(135deg, #eae7ed 0%, #e6dee9 100%);
  opacity: 1;
}

.button-text {
  padding-top: 2px;
  color: #3838688c;
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 14px;
}

.weekly-review-icon {
  color: #3838688c;
  transition: transform 0.1s ease;
}

.weekly-review-icon.rotating {
  transform: rotate(-100deg);
}

.loading-container {
  background: radial-gradient(
      circle at 30% 30%,
      rgba(56, 54, 104, 0.1) 0%,
      rgba(56, 54, 104, 0) 70%
    ),
    linear-gradient(180deg, #f5f3f6 0%, #fcfafb34 90%),
    linear-gradient(90deg, #f5f3f6 0%, #f7f2f6 100%) !important;
}

.loading-spinner {
  color: #3838688c !important;
  animation: spin 1s linear infinite !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.opacity-0 {
  opacity: 0;
}

.animate-fade-in {
  animation: fadeIn 0.15s ease-in forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.date-range {
  float: right;
}

.no-review-message {
  font-size: 12px;
}

.zero-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 110px 12px 36px 12px;
  gap: 8px;
  background: radial-gradient(circle at 30% 30%, #f4c7c784 0%, #f5d4cb00 60%),
    linear-gradient(180deg, #f5f3f6 0%, #fcfafb34 90%),
    linear-gradient(90deg, #f5f3f6 0%, #f7f2f6 100%);
  border-radius: 0.375rem;
  width: 100%;
  margin-bottom: 12px;
}

.zero-state-big-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  gap: 2px;
}

.zero-state-icon {
  color: #e2b9b9b7;
  animation: float 6s ease-in-out infinite;
}

.zero-state-message {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 500;
  font-size: 12px;
  line-height: 1.5;
  color: #322e33;
}

.zero-state-submessage {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  line-height: 1.5;
  color: #322e33;
  margin-bottom: 12px;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.expand-button-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: 2.85rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.expand-button-container:not(.collapsed) {
  opacity: 1;
}

.expand-button {
  background: transparent;
  padding: 8px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-button {
  @apply flex items-center justify-center w-8 h-8 rounded-md hover:bg-[rgba(179,175,215,0.08)];
  color: #322e33;
}

.icon-button-group {
  display: flex;
  gap: 0.25rem;
}

.weekly-review-button.disabled,
.weekly-review-button.disabled:hover,
.weekly-review-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.weekly-review-icon.disabled {
  opacity: 0.5;
}
