.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #89899e56;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content,
.modal-content-big {
  @apply rounded-lg border border-[rgba(179,175,215,0.12)];
  background: white;
  width: 90%;
  max-width: 480px;
  box-shadow: 0 16px 50px rgba(38, 36, 51, 0.08);
  position: relative;
  z-index: 10000;
  text-align: left;
  padding: 8px;
}

.modal-content {
  padding: 20px 24px 24px 24px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.modal-header h3,
.goal-modal-header h3 {
  margin-top: 6px;
  font-size: 1.2rem;
  color: #322e33;
}

.modal-header h3 {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  font-family: "instrument-sans-variable", sans-serif;
}

.goal-modal-header h3 {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-family: "gelica", sans-serif;
}

.goal-instructions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px;
  padding-bottom: 32px;
}

.instructions-text {
  @apply text-sm text-[#322e33];
}

.feedback-textarea {
  @apply border border-[rgba(179,175,215,0.12)] rounded-lg;
  width: 100%;
  min-height: 120px;
  padding: 12px;
  margin-bottom: 4px;
  font-size: 0.9rem;
  resize: vertical;
  font-family: "instrument-sans-variable", sans-serif;
}

.submit-button {
  @apply text-left px-6 pt-2.5 pb-1.5 text-[#fdfafb] rounded-md text-sm;
  background: #3838688c;
  text-align: center;
  box-shadow: 0px 2px 10px #b9bad580;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  font-family: "instrument-sans-variable", sans-serif;
}

.submit-button:hover:not(:disabled) {
  background: #3838689d;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.6);
}

.submit-button:disabled {
  background: linear-gradient(135deg, #f0ecf2 0%, #f1ecf1 100%);
  color: #3838688c;
  box-shadow: none;
}

.dashed-divider {
  height: 2.5px !important;
  margin: 8px 0px 16px 0px;
  background: radial-gradient(circle closest-side, rgb(229, 227, 240) 98%, transparent) 50%/8px 100%,
    linear-gradient(90deg, rgb(229, 227, 240) 50%, transparent 0) 50%/16px 100%;
  background-repeat: repeat-x;
  display: block;
}

.goal-textarea {
  @apply w-full rounded-md min-h-[90px] focus:outline-none resize-none;
  color: #322e33;
  font-family: "instrument-sans-variable", sans-serif;
  padding: 0px 20px;
}

.goal-textarea::placeholder {
  color: #3838688c;
}

.goal-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  padding: 0px 12px 12px 0px;
}

.goal-actions-no-padding {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.skip-button {
  @apply text-[#3838688c] text-sm px-4 pt-2.5 pb-1.5;
  position: relative;
  z-index: 1;
  cursor: pointer;
  border-radius: 0.375rem;
}

.skip-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.375rem;
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

.skip-button:hover::before {
  opacity: 1;
}

.skip-button:active::before {
  background: linear-gradient(135deg, #f1eff4 0%, #f5eff6 100%);
  opacity: 1;
}

.profile-section {
  @apply flex flex-col;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

.profile-section-heading {
  @apply text-sm text-[#3838688c];
}

.privacy-section {
  @apply flex flex-col;
  margin-bottom: 24px !important;
  margin-top: 24px !important;
  gap: 4px;
}

.small-button {
  @apply text-sm px-3 rounded-md;
  color: #3838688c;
  border: 1px solid #3838688c;
  background: transparent;
  transition: all 0.2s ease-in-out;
  padding-top: 4px;
  padding-bottom: 1px;
}

.small-button:hover {
  color: #322e33;
  border-color: #322e33;
}

.profile-textarea {
  @apply w-full rounded-md focus:outline-none resize-none text-sm;
  color: #322e33;
  font-family: "instrument-sans-variable", sans-serif;
  min-height: 60px;
}

/* When not editing, let content determine height */
.profile-textarea[readonly] {
  min-height: 0;
  height: auto;
  overflow: hidden;
}

.profile-textarea::placeholder {
  color: #3838688c;
}

.profile-dashed-divider {
  height: 1.5px !important;
  margin: 2px 0;
  background: radial-gradient(circle closest-side, rgb(229, 227, 240) 98%, transparent) 50%/4px 100%,
    linear-gradient(90deg, rgb(229, 227, 240) 50%, transparent 0) 50%/8px 100%;
  background-repeat: repeat-x;
  display: block;
}
