.icon-button {
  @apply h-8 w-8 rounded bg-transparent flex items-center justify-center text-[#322E33];
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.icon-button-small {
  @apply h-5 w-5 rounded bg-transparent flex items-center justify-center text-[#322E33];
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.icon-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.375rem; /* rounded */
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}

.icon-button:hover::before {
  opacity: 1;
}

.icon-button:active::before {
  background: linear-gradient(135deg, #f1eff4 0%, #f5eff6 100%);
  opacity: 1;
}

.icon-button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.icon-button:disabled::before {
  display: none;
}

.icon-button-group {
  @apply flex items-center gap-2;
  overflow: visible;
  isolation: isolate;
}

.expand-button-container {
  @apply absolute left-9 z-20;
  margin-top: 2.25rem;
  height: fit-content;
  z-index: 1000;
}

.menu-button-container {
  @apply absolute z-50;
}

.menu-popup {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 0.25rem;
  background: white;
  border: 1px solid #e9e9f1;
  border-radius: 0.5rem;
  box-shadow: 0 5px 20px rgba(185, 186, 213, 0.08);
  min-width: 180px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 4px;
  gap: 4px;
}

.menu-item {
  @apply w-full text-left px-4 pt-2 pb-1.5 text-sm text-[#322E33];
  transition: background-color 0.2s ease-in-out;
  border-radius: 0.375rem;
}

.menu-item:last-child {
  border-bottom: none;
}

.menu-item:hover {
  background-color: #f5f3f6;
}

.menu-item:active {
  background-color: #f1eff4;
}

/* Large screens (desktop) */
@media (min-width: 769px) {
  .menu-button-container {
    @apply absolute right-9 top-9;
  }
  /* When both note editor and list are open OR emotion graph is open */
  [data-route^="/note/"] .menu-button-container .icon-button,
  [data-emotion-graph="open"] .menu-button-container .icon-button {
    color: #3838688c; /* Grey color */
  }
}

/* Medium screens (tablet) */
@media (min-width: 361px) and (max-width: 768px) {
  /* Note List route (/) */
  [data-route="/"] .menu-button-container {
    @apply right-2 top-4;
  }
  [data-route="/"] .menu-button-container .icon-button {
    color: #322e33; /* Black color */
  }

  /* Note Editor route (/note/*) */
  [data-route^="/note/"] .menu-button-container {
    @apply right-7 top-7;
  }
  [data-route^="/note/"] .menu-button-container .icon-button {
    color: #3838688c; /* Grey color */
  }

  /* When emotion graph is open */
  [data-emotion-graph="open"] .menu-button-container {
    @apply right-4 top-4;
    display: block;
  }
  [data-emotion-graph="open"] .menu-button-container .icon-button {
    right: 0px;
    top: 0px;
    color: #3838688c; /* Grey color */
  }
}

/* Small screens (mobile) */
@media (max-width: 360px) {
  /* Note List route (/) */
  [data-route="/"] .menu-button-container {
    @apply right-2 top-4;
  }
  [data-route="/"] .menu-button-container .icon-button {
    color: #322e33; /* Black color */
  }

  /* Note Editor route (/note/*) */
  [data-route^="/note/"] .menu-button-container {
    display: none; /* Hidden in editor */
  }

  /* When emotion graph is open */
  [data-emotion-graph="open"] .menu-button-container .icon-button {
    display: none; /* Hidden in editor */
  }
}

/* Update the tooltip styles */
.tooltip {
  @apply absolute invisible opacity-0 text-xs text-[#322E33];
  padding: 6px 12px;
  white-space: nowrap;
  transition: opacity 0.2s ease-in-out;
  border: 1px solid rgba(179, 175, 215, 0.2);
  background: #fdfcfcea;
  backdrop-filter: blur(8px);
  font-family: "Instrument Sans", sans-serif;
  font-weight: 600;
  filter: drop-shadow(0 3px 3px rgba(162, 166, 217, 0.1));
  z-index: 21 !important;
}

/* Position variants */
.tooltip-bottom-right {
  left: calc(100% + 26px);
  top: calc(100% + 15px);
  transform: translate(-50%, -75%);
  border-radius: 4px 12px 12px 12px;
}

.tooltip-bottom-left {
  right: calc(100% + 8px);
  top: calc(100% + 8px);
  transform: translate(16px, -16px);
  border-radius: 12px 4px 12px 12px;
}

.tooltip-top-right {
  left: calc(100% + 14px);
  bottom: 100%;
  transform: translate(-22px, 8px);
  border-radius: 12px 12px 12px 4px;
}

.tooltip-top-center {
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -4px);
  border-radius: 12px;
}

.tooltip-top-left {
  right: calc(100% + 14px);
  bottom: 100%;
  transform: translate(25%, 25%);
  border-radius: 12px 12px 4px 12px;
}

/* Hover states */
.icon-button:hover .tooltip,
.icon-button-small:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0.5s;
}

/* Ensure proper positioning context */
.icon-button,
.icon-button-small {
  position: relative;
  isolation: isolate;
  overflow: visible;
}
