.sign-in-container {
  @apply w-full flex items-center flex-col justify-center h-full gap-3 text-[#322E33];
}

@media (min-width: 401px) {
  .sign-in-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20rem;
  }
}

@media (max-width: 400px) {
  .sign-in-items {
    @apply flex flex-col gap-4 w-full px-8;
  }
}

.sign-in-header {
  @apply text-4xl font-normal text-[#322E33] mb-12;
  font-family: "gelica", sans-serif;
  color: #322e33;
}

.input-field {
  font-family: "instrument-sans-variable", sans-serif;
}

.signin-button {
  @apply text-left px-4 pt-2.5 pb-1.5 text-[#fdfafb] rounded-md;
  background: #3838688c;
  text-align: center;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.5);
  transition: all 0.2s ease-in-out;
}

.google-button {
  @apply text-left px-4 pt-2.5 pb-1.5 text-[#322E33] border border-[rgba(179,175,215,0.2)] rounded-md mt-6;
  background-color: #f5f3f6;
  text-align: center;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.10);
  transition: all 0.2s ease-in-out;
}

.google-button:hover {
  color: #383868bc;
  background-color: rgb(241, 239, 242);
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.20);
}

.signin-button:disabled {
  background: linear-gradient(135deg, #f0ecf2 0%, #f1ecf1 100%);
  color: #3838688c;
  box-shadow: none;
}

.signin-button:not(:disabled):hover {
  background: #3838689d;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.6);
}

.input-label {
  font-family: "instrument-sans-variable", sans-serif;
  color: #322e33;
  margin-bottom: 0.25rem;
}

.input-container {
  @apply flex flex-col;
}

.input-field-container {
  @apply bg-transparent input-field px-3 border border-[rgba(179,175,215,0.2)] rounded-md ;
  background: #f7f2f6b3;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}

.input-field-container::placeholder {
  @apply text-[#38386860];
}

.google-icon {
  font-size: 0.9rem;
  margin-right: 0.35rem;
  margin-bottom: 0.05rem;
}

.small-text-container {
  @apply flex flex-col gap-2;
  width: 20rem;
}

@media (max-width: 400px) {
  .small-text-container {
    @apply flex-col gap-4 pt-4 w-full px-8;
  }
}

.small-text {
  @apply text-sm text-[#3838688c];
  transition: all 0.2s ease-in-out;
}

.small-text:hover {
  text-decoration: underline;
}

.alert-container {
  @apply text-red-700 flex items-center justify-start border  border-red-200 rounded-md bg-red-50;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 12px 8px 12px;
  gap: 8px;
}

.alert-text {
  @apply text-start text-sm;
  padding-top: 2px;
  width: 100%;
}

.message-container {
  @apply text-green-700 flex items-center justify-start border border-green-200 rounded-md bg-green-50;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 12px 8px 12px;
  gap: 8px;
}

.small-text-static {
  @apply text-sm text-[#3838688c];
}

.terms-container {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.terms-container input[type="checkbox"] {
  cursor: pointer;
  border: 1px solid #908FAA !important;
  margin-top: 1px;
  width: 14px;
  height: 14px;
}

/* For modern browsers that support :not(:checked) */
.terms-container input[type="checkbox"]:not(:checked) {
  border: 1px solid #908FAA !important;
}

.terms-container a {
  color: inherit;
  text-decoration: none;
}
