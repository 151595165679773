@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://use.typekit.net/vuw0bky.css");

body {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
}

/* Add any necessary styles for Markdown content */
.markdown-content h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.markdown-content h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.markdown-content p {
  margin-bottom: 1em;
}

.search-button {
  @apply bg-gray-200 text-gray-700 p-2 rounded;
}

.search-button:hover {
  @apply bg-gray-300;
}

.search-button:active {
  @apply bg-gray-400;
}

.search-button {
  transition: background-color 0.2s ease-in-out;
}

@media (min-width: 769px) {
  .sign-out-button {
    @apply absolute top-4 right-4 z-10;
  }
  .route-container {
    @apply pl-4 pt-4;
  }
}

@media (max-width: 768px) {
  .sign-out-button {
    @apply absolute top-2 right-2 text-sm z-10;
  }
  .route-container {
    @apply pt-2;
  }
}

.Toastify__toast-bodys {
  font-family: "instrument-sans-variable", sans-serif;
  font-size: 0.8rem;
}

.loading-state-message {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 500;
  line-height: 1.5;
  color: #322e33;
}
