@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@300;400;500&display=swap");
@import url("https://use.typekit.net/[your-kit-code].css");

.another-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.filter-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.filter-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1.48rem;
  flex-shrink: 0;
  text-align: left;
  width: fit-content;
}

.time-filters {
  position: relative;
  display: flex;
  justify-content: flex-start;
  height: 24px;
  width: fit-content;
  border-radius: 0.25rem;
  background: linear-gradient(90deg, #f5f3f6, #f7f2f6);
  align-items: center;
  overflow: hidden;
  z-index: 1;
  padding-right: 2px;
  padding-left: 2px;
}

.time-filters::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 2px;
  width: 64px;
  height: 18px;
  background: linear-gradient(45deg, #eae8ed, #eee7ee);
  border-radius: 0.25rem;
  transition: transform 0.15s ease-out;
  z-index: -1;
}

.filter-button-container {
  display: inline-flex;
  gap: 2px;
}

.filter-button {
  padding: 0px 16px;
  height: 18px;
  width: 64px;
  border-radius: 0.25rem;
  border: none;
  background: transparent !important;
  color: #3838688c;
  font-family: "gelica", serif;
  font-size: 0.75rem;
  cursor: pointer;
  font-weight: 400;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.filter-button.active {
  background: transparent !important;
}

.time-filters[data-active="0"]::before {
  transform: translateX(0);
}

.time-filters[data-active="1"]::before {
  transform: translateX(66px);
}

.time-filters[data-active="2"]::before {
  transform: translateX(132px);
}

.time-filters[data-active="3"]::before {
  transform: translateX(198px);
}

.graph-content {
  display: flex;
  flex: 1;
  position: relative;
  min-height: 0;
  overflow: hidden;
}

.side-filters {
  width: 32px;
  padding: 1rem 0;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  justify-content: center;
}

.day-filter {
  display: flex;
  padding-top: 3px;
  height: 18px;
  width: 32px;
  border-radius: 0.25rem;
  background: transparent;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #3838688c;
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 500;
  font-size: 0.625rem;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

.day-filter:hover {
  background: linear-gradient(135deg, #f5f3f6 0%, #f7f2f6 100%);
}

.day-filter.active {
  background: linear-gradient(135deg, #f1eff4 0%, #f5eff6 100%);
}

.emotion-graph-container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(179,175,215,0.12);
  border-radius: 0.5rem;
  box-shadow: 0 5px 12px rgba(162,166,217,0.06);
  margin-left: 0.5rem;
  margin-right: 1rem;
  background: #fdfafb;
  width: 100%;
  height: calc(100% - 1rem);
}

.emotion-graph {
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 0;
  max-width: 900px;
  margin: 0 auto;
}

.emotion-graph svg {
  width: 100%;
  height: 100%;
}

/* Preview styles */
.emotion-preview {
  position: absolute;
  background: #fdfafb;
  border: 1px solid rgba(179, 175, 215, 0.12);
  border-radius: 8px;
  padding: 12px;
  width: 280px;
  box-shadow: 0 5px 12px rgba(162, 166, 217, 0.06);
  opacity: 1;
  z-index: 1000;
  transform: translate(0, -50%);
  cursor: pointer;
}

.emotion-preview.visible {
  opacity: 1;
}

.emotion-preview-title {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 500;
  font-size: 14px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  color: #322e33;
}

.emotion-preview-content {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 14px;
  color: #322e33;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
}

.emotion-preview .note-metadata-container {
  margin-top: 8px;
}

.emotion-preview .note-emotions {
  display: flex;
  gap: -3px;
  align-items: center;
}

.emotion-preview .note-editor-emotion {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emotion-preview .note-date {
  @apply text-xs text-[#3838688C] pt-2;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: auto;
  font-variation-settings: "wght" 500;
}

/* Position modifiers */
.emotion-preview.left-side {
  transform: translate(calc(-100%), -50%);
}

.emotion-preview.bottom-side {
  transform: translate(-5px, -50%);
}

.emotion-preview.bottom-left-side {
  transform: translate(calc(-100%), -5px)0;
}

.emotion-preview.top-side {
  transform: translate(0, calc(-100%));
}

.emotion-preview.top-left-side {
  transform: translate(calc(-100%), calc(-100%));
}

/* mobile styles */
@media (max-width: 768px) {
  .emotion-graph-container {
    @apply flex flex-col rounded-lg;
    background: #fdfafb;
    width: 100vw !important;
    margin: 0;
    padding: 0;
    border: none;
  }

  .graph-content {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    overflow: visible;
  }

  .emotion-graph {
    position: relative;
    flex: 1;
    display: flex;
    width: 100%;
    min-width: 0;
    min-height: 0;
    max-width: 350px;
    margin: 0 auto;
  }

  .another-container {
    width: 100vw;
    max-width: none;
    margin: 0;
    padding: 0;
  }

  .filter-bar {
    width: 100%;
    margin-top: .75rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* desktop styles */
@media (min-width: 768px) {
  .emotion-graph-container {
    @apply flex flex-col border border-[rgba(179,175,215,0.12)] rounded-lg drop-shadow-[0_5px_12px_rgba(162,166,217,0.06)] ml-2 mr-4;
    background: #fdfafb;
    width: 100%;
    height: calc(100% - 1rem);
  }
}
