/* Bottom Bar */
.bar-container-wrapper {
  position: fixed;
  bottom: -1px;
  z-index: 100;
  width: 100%;
  /* new styles to center the element */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}
.bar-container {
  background: #FDFCFCea;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(179,175,215,0.12);
  width: 80%;
  text-align: center;
  padding: 15px 0 20px 0;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 3px 24px rgba(162,166,217,0.12);
}

.bar-text {
  font-size: 16px;
  color: rgba(56, 56, 104, 0.55);
}

.icon-button-mobile {
  background: #FDFCFCea;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(179,175,215,0.12);
  box-shadow: 0 3px 24px rgba(162,166,217,0.12);
  border-radius: 8px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
