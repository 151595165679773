.review2024-wrapper {
  z-index: 2;
  position: relative;
  overflow: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  max-width: 100%;
  padding-inline: max(48px, calc((100% - 700px) / 2));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .review2024-wrapper {
    padding-inline: 24px;
  }
}

.review2024-header {
  display: flex;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  position: sticky;
  top: 0;
  z-index: 20;
  background: #fdfafb;
  box-shadow: 0 4px 12px 12px #fdfafb;
  padding-top: 1rem;
}

.review2024-date {
  font-size: 0.875rem;
  color: #3838688c;
  margin-top: 0.45rem;
}

.review2024-title {
  width: 100%;
  background-color: transparent;
  font-size: 1.125rem;
  font-family: "gelica", sans-serif;
  font-variation-settings: "wght" 400;
  margin-bottom: 1.5rem;
  color: #322e33;
  margin-top: 70px;
}

@media (max-width: 768px) {
  .review2024-title {
    margin-top: 2rem;
  }
}

.emotion-summary-section {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.375rem;
  padding: 12px;
  padding-top: 18px;
  overflow: hidden;
}

.emotion-summary-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
}

.emotion-summary-container {
  display: flex;
  /* width: fit-content; */
  flex-direction: column;
  align-items: start;
  background-color: transparent;
  gap: 10px;
}

.year-emotion-summary-number {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 300;
  font-size: 64px;
  line-height: 64px;
}

.emotion-summary-emotion {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
  text-transform: lowercase;
  margin: 0;
}

.week-description {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 14px !important;
  line-height: 150%;
}

.descriptive-words {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 32px;
}

.descriptive-word {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 12px;
}

.descriptive-star {
  font-family: "instrument-sans-variable", sans-serif;
  font-variation-settings: "wght" 400;
  font-size: 7px;
}

@media (max-width: 768px) {
  .year-emotion-summary-number {
    font-size: 32px !important;
    line-height: 32px !important;
  }
}

.review2024-export {
  margin: 0 auto;
}

.export-button {
  @apply px-6 pt-2.5 pb-1.5 text-[#fdfafb] rounded-md text-sm;
  background: #3838688c;
  text-align: center;
  box-shadow: 0px 2px 10px #b9bad580;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  font-family: "instrument-sans-variable", sans-serif;
}

.export-button:hover:not(:disabled) {
  background: #3838689d;
  box-shadow: 0px 2px 10px hsl(238, 25%, 78%, 0.6);
}

.export-button:disabled {
  background: linear-gradient(135deg, #f0ecf2 0%, #f1ecf1 100%);
  color: 3838688c#;
  box-shadow: none;
}

.review2024-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.looking-forwards-grid {
  display: grid;
  gap: 20px;
}

/* Mobile layout (default) */
.looking-forwards-grid {
  grid-template-columns: 1fr;
}

.vertical-divider {
  height: 1px;
  background-color: #ccc;
  width: 100%;
}

/* Desktop layout (768px and above) */
@media (min-width: 768px) {
  .looking-forwards-grid {
    grid-template-columns: 1fr auto 1fr auto 1fr;
    align-items: start;
  }

  .vertical-divider {
    width: 1px;
    height: 90%;
    min-height: 100px;
  }
}

.looking-forwards-item {
  @apply text-sm;
  flex: 1; /* Makes each item take up equal space */
  padding: 1rem;
  /* background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  color: #3838689d;
}

@media (max-width: 768px) {
  .looking-forwards-grid {
    flex-direction: column; /* Stack items vertically on mobile */
    gap: 0.75rem;
  }
}

.looking-forwards-title {
  @apply text-center text-sm pb-2;
}

.section-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 0;
}

.section-divider hr {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.section-divider span {
  font-style: italic;
  padding: 0 1rem;
  color: #3838689d;
  font-size: 0.9rem;
  text-transform: lowercase;
}

.looking-forwards-line {
  @apply text-sm mb-1;
}
